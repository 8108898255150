import './App.css';
import Layout from './Screens/Layout';
function App() {
  return (
  
    <Layout></Layout>
  
  );
}

export default App;
